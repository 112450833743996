.consultation-action {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.form-action-button {
  padding: 8px 16px;
  background-image: linear-gradient(
    to right bottom,
    #c9c6c6,
    #d4d1d2,
    #dedbde,
    #e8e7ea,
    #f1f2f6
  );
  color: black;
  border: none;
  border-radius: 16px;
  cursor: pointer;
  letter-spacing: 1.5px;
  font-weight: 500;
  margin: 8px;
  font-size: 12px;
}

.secondary-button {
  background-image: linear-gradient(
    to right bottom,
    #232526,
    #2a2c2d,
    #323435,
    #393b3d,
    #414345
  );
  color: white;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 999; /* Increase the z-index to ensure modal is on top */
  overflow-y: auto; /* Enable scrolling within the modal if content exceeds viewport */
}

.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  align-items: flex-start;
  max-height: 95vh;
}

.modal-content h2 {
  margin-top: 0px;
}

.modal-content p {
  margin-bottom: 20px;
}

.image-container {
  position: relative;
  height: 140px;
  background-image: url("../assets/modal-banner-image.jpg");
  background-size: cover;
  background-position: center;
}

.close-button {
  position: absolute;
  top: 10px;
  left: 10px;
  width: 30px;
  height: 30px;
  background-color: rgba(0, 0, 0, 0.7);
  color: #fff;
  font-weight: bold;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

/* Disable scrolling on the body when modal is open */
body.modal-open {
  overflow: hidden;
}

/* Prevent scrolling on the screen behind the modal */
.modal-open .consultation-action {
  overflow: hidden;
}

/* Media Queries for Tablet */
@media screen and (max-width: 768px) {
  .image-container {
    height: 120px;
  }

  .modal-content {
    padding: 10px;
  }

  .close-button {
    top: 5px;
    left: 5px;
    width: 20px;
    height: 20px;
    font-size: 14px;
  }
}

/* Media Queries for Mobile */
@media screen and (max-width: 480px) {
  .image-container {
    height: 130px;
  }

  .modal-content {
    padding: 5px;
  }

  .close-button {
    top: 10px;
    left: 10px;
    width: 25px;
    height: 25px;
    font-size: 12px;
  }
}
