.savings-container {
  font-family: Arial, sans-serif;
  font-size: 16px;
  line-height: 1.5;
  margin: 0px 20px 20px;
  text-align: justify;
  padding-right: 30px;
}

.anim-image-container {
  text-align: center;
  margin-bottom: 10px;
}

.anim-image {
  width: 20vw;
  display: block;
  margin: 0 auto;
  height: 40vh;
}

.savings-container {
  font-size: 18px;
}

@media screen and (max-width: 768px) {
  .savings-container {
    font-size: 16px;
  }

  .anim-image {
    width: 40vw;
  }
}

@media screen and (max-width: 480px) {
  .anim-image {
    width: 50vw;
    height: 28vh;
  }

  .savings-container {
    font-size: 14px;
    margin: 10px;
    padding-right: 0px;
  }
}
