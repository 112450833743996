.address-div {
  margin-top: 20px;
  padding: 10px;
}

.address-div h3 {
  margin-bottom: 5px;
  font-size: 18px;
}

.address-div p {
  margin-bottom: 5px;
}

.address-div a {
  display: block;
  color: #007bff; /* Blue color for the link */
  text-decoration: underline;
}
