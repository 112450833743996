@import url("https://fonts.googleapis.com/css2?family=Titillium+Web:wght@300&display=swap");

body {
  font-size: 16px;
  font-weight: normal;
  line-height: 1.5;
  font-family: "Titillium Web", sans-serif;
  letter-spacing: 2px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: bold;
  font-family: "Titillium Web", sans-serif;
  letter-spacing: 1.5px;
}

p {
  font-family: "Helvetica", sans-serif;
  letter-spacing: 1.5px;
}
