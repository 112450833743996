.referral-page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0px 20px;
  margin-top: 20vh;
}

.referral-page {
  max-width: 600px;
  padding: 20px;
  text-align: center;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  flex-direction: column;
}

.social-media-icons-container {
  height: 50vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0px;
  flex-direction: column;
}

.normal-earning-text {
  color: black;
  font-weight: normal;
}

.highlight-earning-text {
  color: #ffb300;
  font-weight: bold;
}

.social-media-icons-container img {
  height: 80%;
  width: 40%;
}

.referral-form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.ref-form-group {
  margin-bottom: 25px;
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Align labels to ref-form-group-start */
  width: 90%;
}

.ref-form-group label {
  font-size: 20px;
}

.referral-input-field {
  display: block;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 14px;
  margin: 0 0 10px; /* Add margin to the bottom of the fields */
}

.radio-group {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.radio-group label {
  margin-right: 16px;
}

input[type="radio"] {
  width: auto;
  margin-right: 8px;
}

.referral-subtitle {
  margin-bottom: 30px;
}

.mobile-number-note {
  font-weight: 600;
  text-align: left;
  margin-bottom: 40px;
  margin-top: -5px;
  color: #e57373;
}

.generate-link-button {
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  width: 30vw;
  align-self: center;
  margin-top: 30px;
}

.generate-link-button:hover {
  background-color: #0056b3;
}

.agree-policy {
  display: flex;
  margin-top: 30px;
}

.agree-policy input {
  margin-right: 10px;
}

.referral-money-policy {
  background-color: #fff;
  padding: 20px;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  margin-top: 20px;
}

.referral-money-policy h2 {
  font-size: 24px;
  margin-bottom: 20px;
}

.referral-money-policy h3 {
  font-size: 18px;
  margin-top: 16px;
  margin-bottom: 12px;
}

.referral-money-policy p {
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 16px;
}

.referral-money-policy a {
  color: #007bff;
  text-decoration: none;
  font-weight: bold;
}

.referral-money-policy a:hover {
  text-decoration: underline;
}

/* Add this to your ReferralPage.css file */

.success-message {
  text-align: center;
  margin-top: 20vh;
}

.success-message p {
  font-size: 18px;
  margin: 10px 0;
}

.success-message input[type="text"] {
  width: 50%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin: 10px;
}

.success-message button {
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  width: 100%;
  max-width: 200px;
  margin: 10px 0;
}

.success-message button:hover {
  background-color: #0056b3;
}

/* Add this to your ReferralPage.css file */

/* Responsive Styles */
@media (max-width: 768px) {
  .referral-page {
    padding: 10px;
  }

  .referral-input-field {
    font-size: 12px;
  }

  .generate-link-button {
    font-size: 12px;
  }

  .social-media-icons-container {
    height: auto; /* Adjust the height for tablets */
    width: 100%; /* Full width for tablets */
    flex-direction: column;
  }

  .social-media-icons-container img {
    height: auto; /* Allow images to scale proportionally */
    width: 60%; /* Adjust the width for tablets */
    margin: 10px 0; /* Add margin between images */
  }
}

@media (max-width: 480px) {
  /* Adjust styles for mobile */
  .referral-page {
    max-width: 95vw;
    padding: 20px;
  }

  .social-media-icons-container img {
    width: 80%;
  }

  .success-message input[type="text"] {
    width: 90%;
  }

  .ref-form-group label {
    font-size: 16px;
  }

  .generate-link-button {
    width: 85vw;
  }
}
