.privacy-policy-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 40px;
  margin-top: 45px;
}

.privacy-policy-container h1 {
  font-size: 28px;
  margin-bottom: 20px;
}

.privacy-policy-container h2 {
  font-size: 22px;
  margin-top: 40px;
  margin-bottom: 20px;
}

.privacy-policy-container p {
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 20px;
}
