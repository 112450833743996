/* YouTubeVideo.css */

.youtube-video {
  position: relative;
  width: 90vw;
  height: 65vh;
  margin: 20px auto; /* Center align the video and add vertical margin */
}

.youtube-video iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

/* Media Queries for Tablet */
@media screen and (max-width: 768px) {
  .youtube-video {
    width: 95vw;
    height: 75vh;
  }
}

/* Media Queries for Mobile */
@media screen and (max-width: 480px) {
  .youtube-video {
    width: 98vw;
    height: 30vh;
  }
}
