.contact-now {
  text-align: center;
  margin: 30px 0px;
}

.contact-now h3 {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
}

.form p {
  font-size: 14px;
}

.contact-options {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.contact-options-button {
  background-image: linear-gradient(
    to right,
    #232526 0%,
    #414345 51%,
    #232526 100%
  );
  margin: 10px;
  padding: 10px 20px;
  text-align: center;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  border-radius: 40px;
  display: block;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  text-decoration: none;
  border: none;
}

.contact-options-button:hover {
  background-position: right center;
  color: #fff;
  text-decoration: none;
}

/* Media Queries for Tablet */
@media screen and (max-width: 768px) {
  .contact-options {
    flex-direction: column;
    align-items: center;
  }

  .contact-options-button {
    margin-top: 10px;
  }
}

/* Media Queries for Mobile */
@media screen and (max-width: 480px) {
  .contact-now h3 {
    font-size: 20px;
  }

  .contact-options-button {
    font-size: 12px;
  }
}
