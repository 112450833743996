.referral-earn-banner {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background: #f5f5f5;
  border-radius: 20px;
  border: 1px solid #ececec;
}

.content-container {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 800px; /* Adjust the max-width as needed */
}

.animation-container {
  margin-right: 20px;
  width: 10vw;
  display: block;
  height: 20vh;
}

.description-container {
  flex: 1;
  text-align: left;
}

.ref-description {
  color: #ffb300;
  margin: 0;
  font-size: 30px;
  letter-spacing: 1.5px;
  font-weight: 500;
}

/* For tablet screens */
@media (max-width: 768px) {
  .content-container {
    align-items: center;
  }

  .description-container {
    margin: 10px 0;
  }

  .animation-container {
    width: 25vw;
    height: 25vh;
  }

  .ref-description {
    font-size: 22px;
  }
}

/* For mobile screens */
@media (max-width: 480px) {
  .content-container {
    flex-direction: column;
    align-items: center;
  }

  .description-container {
    margin: 20px 0;
  }

  .animation-container {
    width: 45vw;
    height: 25vh;
    margin-bottom: 20px;
  }

  .ref-description {
    font-size: 20px;
  }
}
