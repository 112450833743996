/* SocialMediaPost.css */

.social-media-post {
  display: flex;
  flex-direction: column;
  border: 1px solid #ccc;
  padding: 8px;
  margin: 8px;
  border-radius: 4px;
  min-width: 30vw;
  max-width: 30vw;
  /* Add any other styles as needed */
}

.author {
  display: flex;
  align-items: center;
  position: relative; /* To position the share icon relative to the author div */
}

.share-icon {
  position: absolute;
  top: 8px; /* Adjust the top value to control the vertical position of the icon */
  right: 8px; /* Adjust the right value to control the horizontal position of the icon */
  font-size: 24px;
  color: #007bff; /* Change the color as needed */
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: rgba(
    0,
    0,
    0,
    0.1
  ); /* Add a background color for the icon */
  transition: background-color 0.3s ease; /* Add a smooth transition effect */
}

.share-icon:hover {
  /* Add a different background color on hover */
  background-color: rgba(0, 0, 0, 0.2);
}

/* Style the share icon using Font Awesome icons */
.fa-share:before {
  content: "\f1e0";
}

.author img {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: 8px;
  /* Add any other styles as needed */
}

.author-name {
  font-size: 16px;
  font-weight: bold;
  margin: 0;
  /* Add any other styles as needed */
}

.author-user-id {
  font-size: 14px;
  color: #888;
  margin: 0;
  /* Add any other styles as needed */
}

.description {
  font-size: 16px;
  line-height: 1.6;
  margin-top: 8px;
  color: #333;
  /* Add any other styles as needed */
}

.hashtag {
  color: blue;
  /* Add any other styles as needed */
}

.images {
  display: flex;
  flex-wrap: wrap;
  margin-top: 8px;
  /* Add any other styles as needed */
}

.first-image-container {
  position: relative;
  width: 100%;
  cursor: pointer;
}

.first-image-container img {
  width: 100%;
  height: 40vh;
  object-fit: cover;
  border-radius: 8px;
}

.image-indicator {
  position: absolute;
  top: 6px;
  right: 6px;
  background-color: rgba(0, 0, 0, 0.6);
  color: #fff;
  padding: 6px;
  border-radius: 6px;
  font-size: 13px;
}

.image-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.image-modal-content {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.modal-prev-button,
.modal-next-button {
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  border: none;
  font-size: 16px;
  padding: 8px;
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
}

.modal-prev-button {
  left: 8px;
}

.modal-next-button {
  right: 8px;
}

.modal-close-button {
  background-color: #ececec;
  border: none;
  font-size: 16px;
  padding: 8px 16px;
  margin-top: 8px;
  cursor: pointer;
  border-radius: 4px;
  position: absolute;
  right: 40px;
  top: 40px;
}

/* Media Queries for Tablet */
@media screen and (max-width: 768px) {
  .social-media-post {
    min-width: 40vw;
    max-width: 40vw;
  }

  .author img {
    width: 28px;
    height: 28px;
    margin-right: 6px;
  }

  .author-name {
    font-size: 14px;
  }

  .author-user-id {
    font-size: 12px;
  }

  .description {
    font-size: 14px;
  }

  .first-image-container img {
    height: 30vh;
  }

  .image-indicator {
    top: 4px;
    right: 4px;
    font-size: 12px;
  }

  .modal-prev-button,
  .modal-next-button {
    font-size: 14px;
    padding: 6px;
  }

  .modal-close-button {
    font-size: 14px;
    padding: 6px 12px;
    margin-top: 6px;
    right: 32px;
    top: 32px;
  }
}

/* Media Queries for Mobile */
@media screen and (max-width: 480px) {
  .social-media-post {
    min-width: 80vw;
    max-width: 80vw;
  }

  .author img {
    width: 24px;
    height: 24px;
    margin-right: 4px;
  }

  .author-name {
    font-size: 12px;
  }

  .author-user-id {
    font-size: 10px;
  }

  .description {
    font-size: 12px;
  }

  .first-image-container img {
    height: 35vh;
  }

  .image-indicator {
    top: 3px;
    right: 3px;
    font-size: 10px;
  }

  .modal-prev-button,
  .modal-next-button {
    font-size: 12px;
    padding: 4px;
  }

  .modal-close-button {
    font-size: 12px;
    padding: 4px 8px;
    margin-top: 4px;
    right: 24px;
    top: 24px;
  }
}
