.about-us-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 50px;
  margin-top: 38px;
}

.about-us-banner {
  height: 50vh;
  width: 93vw;
  position: relative;
  overflow: hidden; /* Add this property to hide overflowing content */
}

.banner-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.banner-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80%;
  padding: 20px;
}

.quote-container {
  color: #ffffff;
  margin-right: 20px;
}

.about-us-content {
  margin-top: 30px;
  text-align: center;
}

.about-us-content h2 {
  font-size: 28px;
  margin-bottom: 10px;
}

.about-us-content h3 {
  font-size: 24px;
  margin-bottom: 20px;
}

.about-us-content p {
  font-size: 16px;
  line-height: 1.5;
}

/* Media Queries for Tablet */
@media screen and (max-width: 768px) {
  .about-us-container {
    padding: 30px;
  }

  .about-us-content h2 {
    font-size: 24px;
  }

  .about-us-content h3 {
    font-size: 20px;
    margin-bottom: 15px;
  }

  .about-us-content p {
    font-size: 14px;
  }
}

/* Media Queries for Mobile */
@media screen and (max-width: 480px) {
  .about-us-container {
    padding: 20px;
  }

  .about-us-content h2 {
    font-size: 20px;
    margin-bottom: 8px;
  }

  .about-us-content h3 {
    font-size: 18px;
    margin-bottom: 12px;
  }

  .about-us-content p {
    font-size: 12px;
  }
}
