/* Form styles */
.form {
  background-color: rgba(255, 255, 255, 0.8);
  padding: 30px;
  border-radius: 8px;
  width: 20vw;
  text-align: start;
}

.form h2 {
  font-size: 18px;
  margin-bottom: 15px;
}

.note {
  font-size: 14px;
  margin-bottom: 8px;
}

.form-group {
  margin-bottom: 15px;
}

label {
  display: block;
  font-weight: bold;
  margin-bottom: 4px;
}

input[type="text"],
input[type="email"],
input[type="tel"] {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;
}

button[type="submit"] {
  padding: 8px 16px;
  background-image: linear-gradient(
    to right bottom,
    #28313b,
    #303944,
    #38424e,
    #404b57,
    #485461
  );
  color: white;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
  width: 50%;
  border: none;
}

.button-container {
  display: flex;
  gap: 10px; /* Add some horizontal space between the buttons */
  margin-top: 20px; /* Add some space at the top */
}

.call-instead-button {
  background-color: #006994; /* Red color */
  color: white;
  border: none;
  padding: 8px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.2s;
  width: 50%;
  text-decoration: none;
}

.call-instead-button:hover {
  background-color: #00527a; /* Darker red color on hover */
}

/* Media Queries for Tablet */
@media screen and (max-width: 768px) {
  .form {
    width: 50vw;
    padding: 25px;
  }
  .form h2 {
    font-size: 16px;
  }
  .note {
    font-size: 12px;
  }
  label {
    font-size: 14px;
    margin-bottom: 3px;
  }
}

/* Media Queries for Mobile */
@media screen and (max-width: 480px) {
  .form {
    width: 80vw;
    padding: 20px;
  }
  .form h2 {
    font-size: 14px;
  }
  .note {
    font-size: 14px;
  }
  label {
    font-size: 12px;
    margin-bottom: 2px;
  }
  input[type="text"],
  input[type="email"],
  input[type="tel"] {
    padding: 10px;
    font-size: 12px;
  }
  button[type="submit"] {
    padding: 10px 8px;
    font-size: 10px;
  }
}
