/* Default styles for larger screens */
.location-division {
  border: 1px solid #ccc;
  border-radius: 10px; /* Adjust border radius as needed */
  overflow: hidden; /* Clip image within the container */
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  width: 35vw; /* Adjust the container width as needed */
  height: auto;
}

.location-division img {
  max-width: 100%;
  height: auto;
  display: block;
}

@keyframes zoom {
  0% {
    transform: scale(0.8); /* Initial scale */
  }
  50% {
    transform: scale(1); /* Zoom in to 120% size */
  }
  100% {
    transform: scale(0.8); /* Back to the initial scale */
  }
}

.new-division-container {
  position: absolute;
  margin: 8px;
  padding: 3px 10px;
  background: rgba(0, 0, 0, 0.4);
  font-size: small;
  border-radius: 20px;
  border: 1px solid #ececec;
  color: white;
  animation: zoom 3s infinite;
}

/* Media query for screens up to 768px (tablet screens) */
@media (max-width: 768px) {
  .location-division {
    width: 80vw; /* Adjust the container width for tablets */
    margin: 0 auto; /* Center the container horizontally */
  }
}

/* Media query for screens up to 480px (mobile screens) */
@media (max-width: 480px) {
  .location-division {
    width: 90vw; /* Adjust the container width for mobile screens */
    margin: 0 auto; /* Center the container horizontally */
  }

  .new-division-container {
    padding: 3px 8px;
    font-size: 10px;
  }
}
