.other-services {
  margin-top: 20px;
}

.other-services-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 20px;
}

.other-service {
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 20px 50px;
}

.other-service img {
  width: 100px;
  height: 100px;
  border-radius: 10px;
  overflow: hidden;
}

.other-service span {
  font-size: 16px;
  font-weight: 600;
  margin-top: 10px;
}

/* Media Queries for Mobile */
@media screen and (max-width: 480px) {
  .other-service {
    margin: 5px;
  }

  .other-service img {
    width: 80px;
    height: 80px;
    margin: 8vw 8vw 0vw 8vw;
  }

  .other-service span {
    font-size: 14px;
  }
}
