.post-container {
  display: flex;
  overflow-x: auto;
  scroll-behavior: smooth; /* Optional: Enables smooth scrolling */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.post-container::-webkit-scrollbar {
  display: none; /* Hide scrollbars for Chrome, Safari, and Opera */
}

.club {
  display: flex;
  align-items: center;
  margin-top: 8px;
  /* Add any other styles as needed */
}

.club img {
  width: 32px;
  height: 32px;
  border-radius: 6px;
  margin-right: 8px;
  /* Add any other styles as needed */
}

.club-name {
  font-size: 16px;
  font-weight: bold;
  margin: 0;
  /* Add any other styles as needed */
}

.club-id {
  font-size: 14px;
  color: #888;
  margin: 0;
  /* Add any other styles as needed */
}
