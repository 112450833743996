.footer-container {
  background-color: #f8f8f8;
  padding: 20px 0;
}

.footer-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer-links {
  display: flex;
  gap: 25px;
  margin-bottom: 10px;
}

.footer-links a {
  color: #333;
  text-decoration: none;
}

.footer-credits {
  text-align: center;
  font-size: 14px;
  color: #666;
}

.footer-credits p {
  margin: 5px 0;
}

/* Media Queries for Tablet */
@media screen and (max-width: 768px) {
  .footer-links {
    flex-wrap: wrap;
    justify-content: center;
  }

  .footer-links a {
    margin-bottom: 10px;
  }
}

/* Media Queries for Mobile */
@media screen and (max-width: 480px) {
  .footer-links {
    flex-direction: column;
    align-items: center;
    gap: 15px;
  }

  .footer-credits {
    font-size: 12px;
  }
}
