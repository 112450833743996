/* SinglePostScreen.css */

/* Apply flexbox to the parent div */
.singlepost-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
}

/* Main content styles */
.singlepost-content {
  flex: 1; /* This makes the middle div take all available space */
  padding: 16px;
  margin-top: 80px;
  display: flex; /* Add flexbox */
  justify-content: center; /* Center the content horizontally */
  align-items: center; /* Center the content vertically */
  flex-direction: column; /* Align content in a column */
}

.singlepost-content a {
  color: #007bff;
  text-decoration: none;
}
