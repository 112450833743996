/* HomeInteriorInspiration.css */

/* Style for the whole component */
.home-interior-inspiration {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

/* Style for the home buttons at the top */
.home-buttons-inspiration {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
  overflow-x: hidden; /* Hide horizontal scrollbar */
  white-space: nowrap; /* Prevent button text from wrapping */
  position: fixed; /* Fix the buttons at the top */
  top: 13vh;
  left: 0;
  width: 100%; /* Take up the full width of the viewport */
  padding: 10px; /* Add padding for spacing */
  z-index: 999; /* Ensure the buttons are above other content */
  min-width: 100px;
  background-color: rgba(255, 255, 255, 0.8);
}

.home-button-inspiration {
  background-color: #ececec;
  color: black;
  border: none;
  padding: 8px 16px;
  border-radius: 100px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.home-button-inspiration.active {
  background-color: black;
  color: white;
}

/* Style for the images grid */
.home-images-grid-inspiration {
  display: flex;
  gap: 10px;
  width: 100%;
  margin: 0 auto;
  flex-wrap: wrap;
  margin-top: 20vh;
}

.home-image-inspiration {
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  height: 30vh; /* Adjust the height as needed */
  width: 30vw; /* Adjust the width as needed */
}

.home-image-inspiration img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.2s ease-in-out;
}

.home-image-inspiration:hover img {
  transform: scale(1.05);
}

/* Hide the scroll bar for Chrome, Edge, and Safari */
.home-buttons-inspiration::-webkit-scrollbar {
  display: none;
}

/* Media Queries for Tablet */
@media screen and (max-width: 768px) {
  .home-images-grid-inspiration {
    gap: 5px;
  }

  .home-image-inspiration {
    height: 40vh;
    width: 40vw;
  }
}

/* Media Queries for Mobile */
@media screen and (max-width: 480px) {
  .home-buttons-inspiration {
    flex-direction: row; /* Ensure buttons are horizontally placed */
    justify-content: flex-start; /* Align buttons to the start of the container */
    align-items: center; /* Center items vertically */
    overflow-x: auto; /* Allow horizontal scrolling if needed */
    margin-bottom: 10px;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    top: 16vh;
    background: #000;
  }

  .home-button-inspiration {
    white-space: nowrap; /* Prevent button text from wrapping */
    margin-right: 10px; /* Add spacing between buttons */
    border: 1px solid #ececec;
  }

  .home-images-grid-inspiration {
    gap: 5px;
    margin-top: 22vh;
  }

  .home-image-inspiration {
    height: 35vh;
    width: 95vw;
  }
}
