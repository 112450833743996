.solution-page-container {
  padding: 40px;
}

.solution-page-title {
  font-size: 28px;
  text-align: center;
  margin-bottom: 20px;
  margin-top: 50px;
}

.solution-card {
  display: flex;
  align-items: center;
}

.grid-image-style {
  width: 62vw;
  height: 80vh;
  margin: 0 auto; /* Add this line to center align the image */
  display: block; /* Add this line to remove any default inline behavior */
  margin-top: 45px;
}

.solution-content {
  flex-grow: 1;
  margin-left: 20px;
}

.solution-description {
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 20px;
  text-align: justify;
}

@media screen and (max-width: 768px) {
  .solution-card {
    flex-direction: column;
    align-items: center;
  }
}

@media screen and (max-width: 480px) {
  .solution-page-container {
    padding: 10px;
  }

  .solution-card {
    flex-direction: column;
    align-items: center;
  }

  .grid-image-style {
    width: 90vw;
    height: 60vh;
    margin-top: 85px;
  }
}
