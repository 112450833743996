.exhibition-container {
  position: relative;
  width: 100vw;
  overflow: hidden;
}

.image-grid {
  display: flex;
  overflow-x: auto;
  scroll-behavior: smooth;
  align-items: center;
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
}

.image-item {
  flex-shrink: 0;
  margin-right: 10px;
  overflow: hidden;
  height: 30vh;
  width: 20vw;
}

.image-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
  border-radius: 5px;
  overflow: hidden;
}

.image-item:hover img {
  transform: scale(1.1);
}

.scroll-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 40px;
  height: 40px;
  background-color: rgba(0, 0, 0, 0.7);
  color: #fff;
  font-weight: bold;
  font-size: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 1;
}

.left-button {
  left: 10px;
}

.right-button {
  right: 10px;
}

.image-grid::-webkit-scrollbar {
  display: none;
}

.loading-message {
  margin-top: 20px;
  font-size: 16px;
  font-weight: bold;
}

/* Media Queries for Tablet */
@media screen and (max-width: 768px) {
  .image-grid {
    scroll-snap-type: x mandatory;
  }
}

/* Media Queries for Mobile */
@media screen and (max-width: 480px) {
  .image-grid {
    flex-wrap: nowrap;
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;
  }

  .image-item {
    flex-shrink: 0;
    margin-right: 10px;
    overflow: hidden;
    min-width: 80vw; /* Adjust the min-width as needed */
  }
}
