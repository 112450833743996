.call-to-action {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
}

.call-to-action-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.call-to-action-title {
  font-size: 24px;
  color: white;
  text-align: center;
  height: 8vh;
}

.gif-image {
  width: 35vw;
  height: 47vh;
  border-radius: 10px;
  overflow: hidden;
  margin: 10px 0px;
  border: 3px solid white;
}

/* Media Queries for Tablet */
@media screen and (max-width: 768px) {
  .call-to-action {
    margin-top: 30px;
  }

  .gif-image {
    width: 70vw;
    height: auto;
    aspect-ratio: 16/9;
    max-height: 60vh;
  }
}

/* Media Queries for Mobile */
@media screen and (max-width: 480px) {
  .call-to-action {
    margin-top: 20px;
  }

  .call-to-action-title {
    font-size: 18px;
  }

  .gif-image {
    width: 90vw;
    height: auto;
    aspect-ratio: 16/9;
    max-height: 50vh;
  }
}
