.marquee-container {
  overflow: hidden;
  padding: 20px;
  margin: 20px 0;
  width: 100vw;
}

.marquee {
  display: flex;
  animation: marquee-animation 18s linear infinite;
  margin-left: 50px;
}

@keyframes marquee-animation {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-200%);
  }
}

.marquee-image-container {
  margin: 0 10px;
  height: 100px;
  min-width: 150px;
  width: auto;
  margin-right: 20px;
  border: 1px solid white;
  border-radius: 10px;
  padding: 10px 20px;
  background-color: rgba(255, 255, 255, 0.65);
}

.marquee-image {
  object-fit: contain;
  height: 100%;
  width: 100%;
}

/* Media Queries for Mobile */
@media screen and (max-width: 480px) {
  .marquee {
    display: flex;
    animation: marquee-animation 10s linear infinite;
    width: calc(100vw * 4);
    margin-left: 0;
  }

  .marquee-image-container {
    height: 80px;
    width: 200px;
    margin-right: 10px;
    padding: 8px 16px;
  }
}

/* Media Queries for Tablet */
@media screen and (max-width: 768px) {
  .marquee {
    display: flex;
    animation: marquee-animation 15s linear infinite;
    width: 200vw;
  }

  .marquee-image-container {
    height: 120px;
    width: 300px;
    margin-right: 15px;
    padding: 10px 18px;
  }
}
