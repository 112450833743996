.contact-screen-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.contact-screen-content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px;
  text-align: center;
}

.contact-screen-subtitle {
  margin-bottom: 40px;
}

.contact-screen-info {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
}

.contact-screen-methods {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.contact-screen-method {
  display: flex;
  align-items: center;
  margin: 50px;
}

.contact-screen-icon {
  margin-right: 10px;
  font-size: 20px;
}

@media screen and (max-width: 768px) {
  .contact-screen-content {
    padding: 20px;
  }
}

@media screen and (max-width: 480px) {
  .contact-screen-content {
    margin-top: 50px;
  }

  .contact-screen-subtitle {
    font-size: 20px;
  }

  .contact-screen-info {
    flex-direction: column;
    margin-bottom: 20px;
  }

  .contact-screen-methods {
    flex-direction: column;
    align-items: flex-start;
  }

  .contact-screen-method {
    margin-bottom: 10px;
  }

  .contact-screen-icon {
    font-size: 18px;
  }
}
