@import url("https://fonts.googleapis.com/css2?family=Agdasima&family=Labrada&display=swap");

/* Add the code to disable scroll indicator */
body::-webkit-scrollbar {
  display: none;
}

body {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

/* Rest of the CSS styles specific to the Home component */
.header-style {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 10px;
  background-color: black;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 100; /* Updated z-index */
}

.logo-style {
  display: flex;
  align-items: center;
}

.logo-img-style {
  height: 50px;
  margin-right: 10px;
}

.brand-name {
  color: white;
}

.header-button-container {
  display: flex;
  margin-right: 20px;
}

.button-style {
  background-image: linear-gradient(
    to right,
    #77a1d3 0%,
    #79cbca 51%,
    #77a1d3 100%
  );
  padding: 10px 20px;
  text-align: center;
  transition: 0.5s;
  color: white;
  box-shadow: 0 0 20px #eee;
  border-radius: 100px;
  display: block;
  border: none;
  cursor: pointer;
  margin-right: 20px;
  letter-spacing: 1px;
}

.button-style:hover {
  background-position: right center; /* change the direction of the change here */
  color: #fff;
  text-decoration: none;
}

.content {
  width: 100vw;
}

.content-style {
  padding: 80px 20px 20px;
}

.banner {
  height: 100vh;
  position: relative;
  overflow: hidden; /* Add this property to hide overflowing content */
}

.banner video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.banner-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.banner-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80%;
  padding: 20px;
}

.contact-form-container {
  display: block;
  margin-top: 50px;
}

.quote {
  font-size: 30px;
}

.quote-sub {
  font-size: 20px;
  max-width: 30vw;
}

.quote-container {
  color: #ffffff;
  margin-right: 20px;
}

.why-interiox-title {
  display: flex;
  flex-wrap: wrap;
  margin-top: 50px;
  flex-direction: column;
  align-items: center;
  font-size: 30px;
}

/* Why interiox section */
.why-interiox {
  display: flex;
  justify-content: center;
  margin: 100px 30px 10px 30px;
  padding: 20px 0px;
}

/* Point styles */
.point {
  display: flex;
  align-items: center;
  margin-right: 30px;
}

.point:last-child {
  margin-right: 0;
}

.point-image {
  height: 100px;
  border-radius: 10px;
  margin-right: 10px;
}

.point-image-quality {
  height: 120px;
  border-radius: 10px;
  margin-right: 10px;
}

/* Point content styles */
.point-content {
  flex-grow: 1;
  max-width: 25vw;
  text-align: center;
}

.point-content h3 {
  font-size: 20px;
  margin-bottom: 10px;
}

.point-content span {
  font-size: 16px;
}

/* call for action */

.call-for-action {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 20px 0px;
  border-top: #ececec 1px solid;
  background-image: linear-gradient(
    to right,
    #77a1d3 0%,
    #79cbca 100%,
    #79cbca 100%
  );
}

.call-for-action h3 {
  font-size: 30px;
  color: white;
}

.call-for-action p {
  font-size: 20px;
  margin-top: 0px;
  color: white;
}

.exhibition-dev {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 20px 0px;
  border-top: #ececec 1px solid;
}

.view-all-button {
  background-color: black;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 50px;
  cursor: pointer;
  text-decoration: none; /* Remove default underline */
  transition: background-color 0.2s;
  margin-bottom: 20px;
}

.view-all-button:hover {
  background-color: #2980b9;
}

.exhibition-dev p {
  font-size: 30px;
}

.highlight {
  color: red;
  text-decoration: underline;
}

.division-container {
  display: flex;
  justify-content: space-around; /* Space the ImageContainers evenly */
  margin: 16px; /* Add margin for spacing */
  flex-wrap: wrap;
}

/* Media Queries for Tablet */
@media screen and (max-width: 768px) {
  .banner {
    height: 125vh;
    width: 100vw;
  }

  .banner-content {
    flex-direction: column;
    align-items: center;
    text-align: center; /* Add this property to center align the quote content */
  }

  .contact-form-container {
    margin-top: 0px;
  }

  .quote-container {
    margin-right: 0;
    margin-bottom: 20px;
    margin-top: 30px;
  }

  .quote-sub {
    max-width: 80vw;
  }

  .why-interiox {
    flex-wrap: wrap;
  }

  .point {
    min-width: 50vw;
    margin: 20px 0px;
  }

  .point-content {
    max-width: 50vw;
  }
}

/* Media Queries for Mobile */
@media screen and (max-width: 480px) {
  .header-style {
    align-items: flex-start;
  }

  .logo-style {
    margin-bottom: 10px;
  }

  .logo-img-style {
    height: 50px;
    margin-right: 10px;
  }

  .banner {
    height: 60vh;
    width: 100vw;
    margin-top: 10vh;
  }

  .quote-container {
    margin: 0px;
    backdrop-filter: blur(4px);
    padding: 15px;
    border-radius: 10px;
  }

  .contact-form-container {
    display: none;
  }

  .brand-name {
    font-size: 20px;
  }

  .header-button-container {
    margin-right: 10px;
  }

  .button-style {
    margin-right: 10px;
    padding: 8px 16px;
    font-size: 12px;
  }

  .content-style {
    padding: 40px 10px 10px;
  }

  .banner-content {
    width: 90%;
    text-align: center; /* Add this property to center align the quote content */
  }

  .quote-container {
    margin-right: 0;
    margin-top: 20px; /* Add margin-top to create space below the header */
  }

  .quote {
    font-size: 24px;
  }

  .quote-sub {
    font-size: 16px;
    max-width: 80vw;
  }

  .why-interiox-title {
    margin-top: 40px;
    font-size: 24px;
  }

  .why-interiox {
    margin-top: 0px;
    padding-top: 0px;
  }

  .point {
    min-width: 50vw;
  }

  .point-content {
    max-width: 90vw;
    text-align: center;
  }

  .point-image {
    height: 70px;
  }

  .point-image-quality {
    height: 85px;
  }

  .point-content h3 {
    font-size: 16px;
    margin-bottom: 8px;
  }

  .point-content p {
    font-size: 14px;
  }

  .call-for-action {
    width: 100vw;
    text-align: center;
  }

  .call-for-action h3 {
    font-size: 24px;
  }

  .call-for-action p {
    font-size: 16px;
  }

  .exhibition-dev p {
    font-size: 24px;
    margin-left: 10px;
  }
}
