.image-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.image-modal-content {
  position: relative;
  width: 75vw;
  height: 75vh;
  min-width: 50vw;
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.modal-image {
  width: auto;
  height: 100%;
  object-fit: contain;
  position: relative;
  border-radius: 10px;
  border: 2px solid white;
}

.modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 5px 10px;
  background-color: rgba(0, 0, 0, 0.7);
  color: #fff;
  cursor: pointer;
  border-radius: 20px;
  border: none;
}

/* Media Queries for Tablet and Laptop */
@media screen and (max-width: 1024px) {
  .image-modal-content {
    width: 90vw;
    height: 90vh;
    min-width: 60vw;
    min-height: 60vh;
  }
}

/* Media Queries for Mobile */
@media screen and (max-width: 480px) {
  .image-modal-content {
    width: 95vw;
    height: 95vh;
    min-width: 70vw;
    min-height: 70vh;
  }

  .modal-image {
    height: auto;
    width: 100%;
  }
}
